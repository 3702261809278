import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCheckoutForm, successfulOrder } from "../../store/cart/cart";
import Button from "../Button/Button";
import { Formik, Form, Field } from "formik";
import {
  setCategoryLevel,
  setCategoryID,
} from "../../store/catalogue/catalogueSlice";
import CloseMenu from "./images/CloseMenu";
import "./CheckoutForm.css";
import validationShema from "./validationShena";
import initialValues from "./initialValues";
import useTelegram from "../../hooks/useTelegram";

const CheckoutForm = () => {
  const { user, tg } = useTelegram();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shoppingCart = useSelector((state) => state.cart.shoppingCart);
  const savedInfo = JSON.parse(localStorage.getItem("userInfo"));

  var selects = document.getElementsByTagName("select");

  // Проходимся по всем элементам select
  for (var i = 0; i < selects.length; i++) {
    // Добавляем обработчик события touchstart для каждого элемента select
    selects[i].classList.add("checkoutFormInput");
    selects[i].addEventListener("touchstart", function (e) {
      // Отменяем стандартное поведение элемента select
      e.preventDefault();
      // Открываем список option программно
      this.size = this.options.length;
    });

    // Добавляем обработчик события blur для каждого элемента select
    selects[i].addEventListener("blur", function () {
      // Закрываем список option программно
      this.size = 1;
    });
  }

  return (
    <>
      <Formik
        className="checkoutForm"
        initialValues={{ ...initialValues, ...savedInfo }}
        validationSchema={validationShema}
        onSubmit={async (data) => {
          const {
            fullName,
            nurseryName,
            deliveryAddress,
            telephone,
            isDeliveryInCity,
            paymentMethod,
            comments,
          } = data;
          const userOrder = {
            fullName,
            nurseryName,
            deliveryAddress,
            telephone,
            isDeliveryInCity,
            paymentMethod,
            comments,
            shoppingCart,
            user,
            userID: tg.user,
            // userID: DEBUG ? { id: 0 } : tg.user,
          };
          localStorage.setItem("userInfo", JSON.stringify(data));

          try {
            const data = await fetch(
              `https://breader.detta.com.ua:4000/placeorder`,
              {
                // mode: "no-cors",
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
                body: JSON.stringify(userOrder),
              }
            ).then((res) => res.json());
            if (data.status >= 200 || data.status <= 300) {
              localStorage.removeItem("inCart");
              dispatch(successfulOrder());
              dispatch(setCategoryLevel(0));
              dispatch(setCategoryID(null));
              navigate(`/succesfullorder/${data.orderNumber}`);
            }
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {(props) => {
          return (
            <div className="checkoutForm">
              <div className="closeCheckouMenu">
                <span onClick={() => dispatch(setCheckoutForm(false))}>
                  <CloseMenu />
                </span>
              </div>
              <h2 className="checkoutFormTitle">Оформлення замовлення</h2>
              <Form>
                <Field
                  className="checkoutFormInput"
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="ПІБ"
                  style={
                    props.errors.fullName && props.touched.fullName
                      ? { border: "1px solid red" }
                      : null
                  }
                />

                <Field
                  className="checkoutFormInput"
                  type="number"
                  id="telephone"
                  name="telephone"
                  placeholder="Телефон"
                  style={
                    props.errors.telephone && props.touched.telephone
                      ? { border: "1px solid red" }
                      : null
                  }
                />
                <Field
                  className="checkoutFormInput"
                  type="text"
                  id="nurseryName"
                  name="nurseryName"
                  placeholder="Назва Розплідника"
                  style={
                    props.errors.nurseryName && props.touched.nurseryName
                      ? { border: "1px solid red" }
                      : null
                  }
                />
                <Field
                  className="checkoutFormInput"
                  as="select"
                  id="isDeliveryInCity"
                  name="isDeliveryInCity"
                  onChange={(e) => {
                    props.setFieldValue("paymentMethod", "");
                    props.setFieldValue("isDeliveryInCity", e.target.value);
                  }}
                  style={
                    props.errors.isDeliveryInCity &&
                    props.touched.isDeliveryInCity
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Виберіть місто
                  </option>
                  <option value="Kyiv">Київ</option>
                  <option value="Regions">Регіони</option>
                </Field>
                <Field
                  className="checkoutFormInput"
                  as="select"
                  id="paymentMethod"
                  name="paymentMethod"
                  disabled={props.values.isDeliveryInCity === "" && true}
                  style={
                    !props.errors.isDeliveryInCity &&
                    props.touched.isDeliveryInCity &&
                    props.errors.paymentMethod &&
                    props.touched.paymentMethod
                      ? { border: "1px solid red" }
                      : null
                  }
                >
                  <option value="" disabled selected>
                    Спосіб оплати
                  </option>
                  {props.values.isDeliveryInCity === "Kyiv" && (
                    <option
                      style={{
                        display:
                          props.values.isDeliveryInCity === "Kyiv"
                            ? "block"
                            : "none",
                      }}
                      value="Готівка"
                    >
                      Готівка
                    </option>
                  )}

                  <option value="На розрахунковий рахунок">
                    На розрахунковий рахунок
                  </option>
                  <option value="Накладений платіж">Накладений платіж</option>
                </Field>
                <Field
                  className="checkoutFormInput"
                  type="text"
                  id="deliveryAddress"
                  name="deliveryAddress"
                  placeholder="Адреса доставки"
                  style={
                    props.errors.deliveryAddress &&
                    props.touched.deliveryAddress
                      ? { border: "1px solid red" }
                      : null
                  }
                />
                <Field
                  as="textarea"
                  className="checkoutFormCommentsArea"
                  placeholder="Коментарі"
                  id="comments"
                  name="comments"
                />
                <Button
                  className="checkoutOrderBtn"
                  type="submit"
                  style={{ color: props.isValid ? "" : "red" }}
                >
                  Оформити замовлення
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
      <div className="modalCheckoutForm"></div>
    </>
  );
};

export default CheckoutForm;

{
  /* <>
<div className="checkoutForm">
  <div className="closeCheckouMenu">
    <span onClick={() => dispatch(setCheckoutForm(false))}>
      <CloseMenu />
    </span>
  </div>
  <h2 className="checkoutFormTitle">Оформлення замовлення</h2>
  <form
    onSubmit={async (e) => {
      const userOrder = {
        fullName,
        nurseryName,
        deliveryAddress,
        telephone,
        isDeliveryInCity,
        paymentMethod,
        // email,
        comments,
        shoppingCart,
      };
      e.preventDefault();
      try {
        // "http://localhost:4000/placeorder

        const data = await fetch(
          "https://breader.detta.com.ua:4000/placeorder",
          {
            // mode: "no-cors",
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(userOrder),
          }
        ).then((res) => res.json());
        console.log(data);
        if (data.status === 200) {
          localStorage.removeItem("inCart");
          dispatch(successfulOrder());
          dispatch(setCategoryLevel(0));
          dispatch(setCategoryID(null));
          navigate(`/succesfullorder/${data.orderNumber}`);
        }
      } catch (err) {
        console.log(err);
      }
    }}
  >
    <input
      onChange={(e) => {
        handleInputChange(e.target.value, setFullName, "fullName");
      }}
      value={fullName}
      className="checkoutFormInput"
      type="text"
      placeholder="ПІБ *"
      required
    />

    <input
      onChange={(e) => {
        handleInputChange(e.target.value, setNurseryName, "nurseryName");
      }}
      value={nurseryName}
      className="checkoutFormInput"
      type="text"
      placeholder="Назва розплідника *"
      required
    />

    <select
      onChange={(e) => {
        handleInputChange(
          e.target.value,
          setIsDeliveryInCity,
          "isDeliveryInCity"
        );
      }}
      className="checkoutFormInput"
    >
      <option value="" disabled>
        Виберіть місто
      </option>
      <option value="Kyiv">Київ</option>
      <option value="Regions">Регіони</option>
    </select>
    {isDeliveryInCity && (
      <>
        <select
          onChange={(e) => {
            handleInputChange(
              e.target.value,
              setIsDeliveryInCity,
              "paymentMethod"
            );
          }}
          className="checkoutFormInput"
          value={paymentMethod}
        >
          <option value="" disabled>
            Спосіб оплати
          </option>
          <option
            // className={
            //   isDeliveryInCity === "Kyiv"
            //     ? "checkoutDisplayOption"
            //     : "checkoutNotDisplayOption"
            // }
            style={{
              display: isDeliveryInCity === "Kyiv" ? "block" : "none",
            }}
            value="cash"
          >
            Готівка
          </option>
          <option className="checkoutDisplayOption" value="forRR">
            На розрахунковий рахунок
          </option>
          <option className="checkoutDisplayOption" value="С.O.D">
            Накладений платіж
          </option>
        </select>

        <input
          onChange={(e) => {
            handleInputChange(
              e.target.value,
              setDeliveryAddress,
              "deliveryAddress"
            );
          }}
          value={deliveryAddress}
          className="checkoutFormInput"
          type="text"
          placeholder="Адреса доставки"
        />
        <input
          onChange={(e) => {
            handleInputChange(e.target.value, setTelephone, "telephone");
          }}
          value={telephone}
          className="checkoutFormInput"
          type="number"
          placeholder="Телефон *"
          required
        />
        <textarea
          onChange={(e) => {
            handleInputChange(e.target.value, setComments, "comments");
          }}
          value={comments}
          className="checkoutFormCommentsArea"
          placeholder="Коментарі"
        />
      </>
    )}
    <Button className="checkoutOrderBtn" type="submit">
      {" "}
      Оформити замовлення
    </Button>
  </form>
</div>

<div className="modalCheckoutForm"></div>
</> */
}
