import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCategoryID,
  setCategoryLevel,
  setIsMenuOpen,
  toggleIsMenuOpen,
} from "../../store/catalogue/catalogueSlice";
import { useLocation, useNavigate } from "react-router-dom";
import BurgerMenuItems from "./BurgerMenu/BurgerMenu";
import "./Header.css";
import CartImage from "./images/CartImage";
import { setCheckoutForm } from "../../store/cart/cart";
import CloseMenu from "./images/CloseMenu";
import CallUs from "./images/CallUs";
import Logo from "./images/Logo";
import SearchBar from "../SearchBar/SearchBar";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(
    (state) => state.catalogue.catalogueCategories[0]
  );

  const isLoading = useSelector((state) => state.catalogue.isLoading);
  const isMenuOpen = useSelector((state) => state.catalogue.isMenuOpen);
  const productsInCart = useSelector((state) => state.cart.shoppingCart);

  return (
    <header>
      <BurgerMenuItems />
      {isMenuOpen
        ? document.body.classList.add("toggleScroll")
        : document.body.classList.remove("toggleScroll")}
      {isMenuOpen && (
        <div
          onClick={(e) => {
            if (e.target.closest("div").className === "menuContainer") {
              dispatch(setIsMenuOpen());
            }
          }}
          className="menuContainer"
        >
          <div className="menuList">
            <div>
              {" "}
              <div className="menuListLogo">
                <Logo />
              </div>
              <h2 className="menuListTitle">Каталог</h2>
              <nav>
                <ul>
                  {categories.map((el) => {
                    return (
                      <li
                        className="menuListItem"
                        onClick={() => {
                          dispatch(setCategoryLevel(1));
                          dispatch(setCategoryID(el.id));
                          dispatch(toggleIsMenuOpen());
                          navigate(`${el.id}`, { replace: true });
                        }}
                      >
                        <a className="menuItem">{el.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>

            <div className="menuListContacts">
              <h2 className="menuListContactsTitle">Контакти</h2>
              <div className="menuListCallItemsWrapper">
                <CallUs />
                <div>
                  {" "}
                  <a className="menuListCallItemLink" href="tel:+380631687259">
                    +380631687259
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className="headerLogo"
        onClick={() => {
          dispatch(setIsMenuOpen(false));
          navigate("/", { replace: true });
          dispatch(setCategoryLevel(0));
          dispatch(setCategoryID(null));
          dispatch(setCheckoutForm(false));
        }}
      />
      <div
        onClick={() => {
          if (!isLoading) {
            navigate("/cart");
          }
        }}
        style={{ display: "flex", position: "relative" }}
      >
        {
          <div
            style={{
              position: "absolute",
              left: "12px",
              top: "23px",
              width: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p style={{ color: "white", fontSize: "10px" }}>
              {!isLoading &&
                productsInCart.reduce((acc, el) => acc + el.orderedQty, 0)}
            </p>
          </div>
        }
        {/* <div>
          <SearchBar />
        </div> */}
        <div
          onClick={() => isMenuOpen && dispatch(setIsMenuOpen(false))}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CartImage />
        </div>
      </div>
    </header>
  );
};

export default Header;
