const initialValues = {
  fullName: "",
  nurseryName: "",
  deliveryAddress: "",
  telephone: "+380",
  isDeliveryInCity: "",
  paymentMethod: "",
  // email,
  comments: "",
};

export default initialValues;
