import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCatalogueCategories = createAsyncThunk(
  "fetchCatalogueCategories",
  async (params, { rejectWithValue }) => {
    try {
      const data = await fetch(
        "https://breader.detta.com.ua:4000/getProductsData",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((data) => data.json());

      return { data, params };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const catalogueSlice = createSlice({
  name: "catalogue",
  initialState: {
    catalogueProducts: [],
    catalogueCategories: [],
    isLoading: true,
    categoryLevel: 0,
    categoryID: null,
    isMenuOpen: false,
    hasNextCategory: null,
    requestedProducts: [],
  },
  reducers: {
    // orderCompleted: (state) => {
    //   categoryLevel: 0
    // },
    sortRequestedProducts: (state, action) => {
      const findIndex = state.requestedProducts.findIndex(
        (el) => el.sku === action.payload.sku
      );
      state.requestedProducts[findIndex].price =
        action.payload.requestedEl.price;
      state.requestedProducts[findIndex].barcode =
        action.payload.requestedEl.barcode;
      state.requestedProducts[findIndex].count =
        action.payload.requestedEl.count;
      state.requestedProducts[findIndex].sku = action.payload.requestedEl.sku;
    },
    setSortedProducts: (state, action) => {
      state.requestedProducts = action.payload;
      state.isLoading = false;
    },
    setIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    toggleIsMenuOpen: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    setCategoryLevel: (state, action) => {
      state.categoryLevel = action.payload;
    },
    setCategoryID: (state, action) => {
      state.categoryID = action.payload;
    },
    incrementCategoryLevel: (state, action) => {
      state.categoryID = action.payload;
      state.categoryLevel += 1;
    },
    decrementCategoryLevel: (state, action) => {
      state.categoryID = action.payload;
      state.categoryLevel -= 1;
    },
    setCatalogueProducts: (state, action) => {
      state.catalogueProducts = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCatalogueCategories.fulfilled, (state, action) => {
      const hasParams = action.payload.params;
      if (hasParams?.childID) {
        state.categoryLevel = 2;
        state.categoryID = hasParams.childID;
      } else if (hasParams?.id) {
        state.categoryLevel = 1;
        state.categoryID = hasParams.id;
      }

      const { categories, products } = action.payload.data;

      console.log(products);

      const categoriesList = [[], [], []];
      categories.map((el) => {
        if (el.level === 1) {
          categoriesList[el.level - 1].push(el);
        } else if (el.level === 2) {
          categoriesList[el.level - 1].push(el);
        } else {
          categoriesList[el.level - 1].push(el);
        }
      });

      // Put function  intro seperate file

      const deleteUnnecessaryCategories = (categories, categoryName) => {
        const categoriesCopy = categories;
        const categoryIndex = categoriesCopy.findIndex(
          (el) => el.name === categoryName
        );
        categoriesCopy.splice(categoryIndex, 1);
        return categoriesCopy;
      };
      categoriesList[0] = deleteUnnecessaryCategories(
        categoriesList[0],
        "Корм развес"
      );

      // categoriesList[0] = deleteUnnecessaryCategories(
      //   categoriesList[0],
      //   "Affinity"
      // );

      // const changeCategoryLevelAndDeletedCategory = (
      //   categoryArr,
      //   categoryName
      // ) => {
      //   // const categoriesFlow = [];
      //   let categoriesID = [];
      //   const copyArr = [];
      //   categoryArr.forEach((el, index) => {
      //     if (index === 0) {
      //       const affectedElementIndex = el.findIndex(
      //         (item) => item.name === categoryName
      //       );
      //       categoriesID.push(el[affectedElementIndex].id);
      //       // categoriesFlow.push(el[affectedElementIndex]);
      //       el.splice(affectedElementIndex, 1);
      //       copyArr.push(el);
      //     } else if (index === 1) {
      //       const affectedElements = el.filter((item, index) => {
      //         if (item.child_categories[0] === categoriesID[0]) {
      //           el.splice(index, 1);
      //           return item;
      //         }
      //       });
      //       categoriesID.splice(0, 1);
      //       for (let i = 0; i < affectedElements.length; i++) {
      //         categoriesID.push(affectedElements[i].id);
      //       }
      //       copyArr[0].push(...affectedElements);
      //       copyArr.push(el);
      //     } else {
      //       const affectedIndexes = [];
      //       const affectedElements = [];
      //       const elementCopy = el;
      //
      //       for (let i = 0; i <= categoriesID.length; i++) {
      //         el.filter((item, index) => {
      //           if (item.child_categories[0] === categoriesID[i]) {
      //             affectedIndexes.push(index);
      //             affectedElements.push(item);
      //           }
      //         });
      //       }
      //       // delete unnesessary elements from arr
      //       for (let i = 0; i <= affectedElements.length - 1; i++) {
      //         const findItemIndex = el.findIndex(
      //           (item) => item.id === affectedElements[i].id
      //         );
      //         el.splice(findItemIndex, 1);
      //       }
      //       //
      //
      //       copyArr[1].push(...affectedElements);
      //       copyArr.push(el);
      //     }
      //   });
      // };
      // changeCategoryLevelAndDeletedCategory(categoriesList, "Affinity");

      const brandPriority = [
        "Advance",
        "Brekkies",
        "Almo Nature",
        "Stefanplast",
        "Flexi",
        "Baldecchi",
        "Doggy Dolly",
        "Coralpina",
        "Ветпрепарати",
        "Lindocat",
        "Наші партнери",
      ];
      // delete Корм Развес & Наші партнери з массиву

      const sortedProducts = categoriesList[0].sort((a, b) => {
        const priorityA = brandPriority.indexOf(a.name);
        const priorityB = brandPriority.indexOf(b.name);
        return priorityA - priorityB;
      });
      categoriesList[0] = sortedProducts;
      //

      state.catalogueCategories = categoriesList;
      state.catalogueProducts = products;
      state.hasNextCategory = true;
      state.isLoading = false;
    });
  },
});

export const {
  setCatalogueProducts,
  // addProductToCart,
  setIsLoading,
  incrementCategoryLevel,
  setCategoryLevel,
  decrementCategoryLevel,
  toggleIsMenuOpen,
  setCategoryID,
  setRequestedProducts,
  setSortedProducts,
  sortRequestedProducts,
  setIsMenuOpen,
} = catalogueSlice.actions;

export default catalogueSlice.reducer;
