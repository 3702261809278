import React from "react";

const Logo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 664.63 321.32">
      {" "}
      <defs>
        <style>{`.cls-1{fill:#fff;}.cls-2{fill:#eb5d3f;}`}</style>
      </defs>
      <g id="Шар_2" data-name="Шар 2">
        <g id="Слой_1" data-name="Слой 1">
          <path
            class="cls-1"
            d="M28.81,299.9a24.66,24.66,0,0,0,11.42,2.62,26.81,26.81,0,0,0,13.32-3.32,23.83,23.83,0,0,0,9.25-9.35,30,30,0,0,0,0-27.62,23.82,23.82,0,0,0-9.3-9.35h0a26.86,26.86,0,0,0-13.27-3.32,24.73,24.73,0,0,0-11.72,2.77,21.69,21.69,0,0,0-8.36,7.92l-.23.37V250H13.41v71.36h6.81V291.88l.23.35A21.78,21.78,0,0,0,28.81,299.9ZM20.12,276a21.92,21.92,0,0,1,2.55-10.65,18.62,18.62,0,0,1,7-7.26,19.71,19.71,0,0,1,10.06-2.6,19.75,19.75,0,0,1,10,2.6,18.47,18.47,0,0,1,7.06,7.26A21.81,21.81,0,0,1,59.35,276a22.09,22.09,0,0,1-2.55,10.7A18,18,0,0,1,49.74,294a19.94,19.94,0,0,1-10,2.55A19.88,19.88,0,0,1,29.68,294a18.16,18.16,0,0,1-7-7.26A22.21,22.21,0,0,1,20.12,276Z"
          />
          <path
            class="cls-1"
            d="M80.87,262.33A28,28,0,0,0,77.55,276,27,27,0,0,0,81,289.65a24.57,24.57,0,0,0,9.65,9.45,29,29,0,0,0,14.21,3.42,29.29,29.29,0,0,0,11.43-2.18,22.42,22.42,0,0,0,8.59-6.25l-3.82-4.38a19.73,19.73,0,0,1-7,5,22.6,22.6,0,0,1-9,1.75c-5.78,0-10.62-1.7-14.41-5.05a18.87,18.87,0,0,1-6.31-13.26v-.13h43.78l.09-2.07A28.38,28.38,0,0,0,125,262.28a24,24,0,0,0-9-9.35,25.29,25.29,0,0,0-13-3.37,25.57,25.57,0,0,0-13,3.37A24.06,24.06,0,0,0,80.87,262.33Zm9.35-2.09a19.33,19.33,0,0,1,25.63,0,19.17,19.17,0,0,1,5.86,12.66V273H84.35v-.13A19.17,19.17,0,0,1,90.22,260.24Z"
          />
          <path
            class="cls-1"
            d="M150,298.58c2.62,2.61,6.4,3.94,11.25,3.94a19.92,19.92,0,0,0,6.08-.94,13.39,13.39,0,0,0,4.81-2.65l-2.38-4.84a10.6,10.6,0,0,1-3.45,1.91,13,13,0,0,1-4.26.7,9,9,0,0,1-6.8-2.47c-1.58-1.64-2.38-4.06-2.38-7.2V255.68h15.91V250H152.88V238.53h-6.81V250h-9.35v5.72h9.35v31.75C146.07,292.21,147.4,296,150,298.58Z"
          />
          <path
            class="cls-1"
            d="M230.68,299.9a24.73,24.73,0,0,0,11.43,2.62,26.81,26.81,0,0,0,13.32-3.32,23.9,23.9,0,0,0,9.25-9.35,30.06,30.06,0,0,0,0-27.62,23.82,23.82,0,0,0-9.3-9.35,26.89,26.89,0,0,0-13.27-3.32,24.7,24.7,0,0,0-11.72,2.77,21.69,21.69,0,0,0-8.36,7.92l-.23.37V250h-6.51v71.36h6.81V291.88l.23.35A21.82,21.82,0,0,0,230.68,299.9ZM222,276a21.92,21.92,0,0,1,2.55-10.65,18.62,18.62,0,0,1,7-7.26,20.65,20.65,0,0,1,20.06,0,18.53,18.53,0,0,1,7.06,7.26A21.81,21.81,0,0,1,261.23,276a22.09,22.09,0,0,1-2.55,10.7,18.08,18.08,0,0,1-7.06,7.26,21,21,0,0,1-20.06,0,18.16,18.16,0,0,1-7-7.26A22.21,22.21,0,0,1,222,276Z"
          />
          <path
            class="cls-1"
            d="M309.24,256.08l1.57.09v-6.61a25.22,25.22,0,0,0-12,2.67,17.07,17.07,0,0,0-7.36,7.9l-.23.51V250H284.7V302h6.81V275.5c0-6.11,1.59-10.92,4.71-14.31S303.73,256.08,309.24,256.08Z"
          />
          <path
            class="cls-1"
            d="M360.1,252.93a29,29,0,0,0-27.13,0,24.65,24.65,0,0,0-9.5,9.4,28.69,28.69,0,0,0,0,27.32,24.92,24.92,0,0,0,9.5,9.45,28.61,28.61,0,0,0,27.13,0,24.66,24.66,0,0,0,9.45-9.45,29.07,29.07,0,0,0,0-27.32A24.39,24.39,0,0,0,360.1,252.93Zm3.45,33.76a17.85,17.85,0,0,1-7,7.26,21.1,21.1,0,0,1-20.11,0,18.1,18.1,0,0,1-7-7.26,23.72,23.72,0,0,1,0-21.4,18.22,18.22,0,0,1,7-7.26,21.1,21.1,0,0,1,20.11,0,17.91,17.91,0,0,1,7,7.26,24.15,24.15,0,0,1,0,21.4Z"
          />
          <path
            class="cls-1"
            d="M437.11,302V228.48H430.3v31.73l-.23-.36a21.75,21.75,0,0,0-8.36-7.66,24.73,24.73,0,0,0-11.43-2.63A26.85,26.85,0,0,0,397,252.88a23.82,23.82,0,0,0-9.3,9.35,29.78,29.78,0,0,0,0,27.52,24.08,24.08,0,0,0,9.3,9.4,26.46,26.46,0,0,0,13.26,3.37A24.62,24.62,0,0,0,422,299.75a21.53,21.53,0,0,0,8.35-8l.23-.38V302Zm-9.26-15.33a18.29,18.29,0,0,1-7,7.26,20.92,20.92,0,0,1-20,0,18.16,18.16,0,0,1-7-7.26,23.72,23.72,0,0,1,0-21.4,18.36,18.36,0,0,1,7-7.26,20.92,20.92,0,0,1,20,0,18.27,18.27,0,0,1,7,7.26,23.68,23.68,0,0,1,0,21.4Z"
          />
          <path
            class="cls-1"
            d="M480.49,302.52a23,23,0,0,0,11.08-2.62,19.37,19.37,0,0,0,7.61-7.36l.23-.4V302h6.51V250h-6.81v27.33c0,6-1.61,10.7-4.8,14s-7.61,5.07-13.12,5.07c-5.05,0-9-1.46-11.73-4.32s-4.11-7.1-4.11-12.62V250h-6.81v30.21c0,7.29,2,12.9,5.88,16.67S473.73,302.52,480.49,302.52Z"
          />
          <path
            class="cls-1"
            d="M564.76,263.19l5.07-3.44a20,20,0,0,0-8.29-7.56,26.92,26.92,0,0,0-12-2.63,28.21,28.21,0,0,0-13.81,3.37,24.51,24.51,0,0,0-9.55,9.4,28.72,28.72,0,0,0,0,27.37,24.37,24.37,0,0,0,9.55,9.45,28.08,28.08,0,0,0,13.81,3.37,27,27,0,0,0,12-2.62,20,20,0,0,0,8.34-7.56l-5.07-3.44a16.22,16.22,0,0,1-6.55,5.7,20.1,20.1,0,0,1-8.7,1.9A20.63,20.63,0,0,1,539.25,294a18.13,18.13,0,0,1-7.11-7.21,23.83,23.83,0,0,1,0-21.45,18.14,18.14,0,0,1,7.11-7.26,20.74,20.74,0,0,1,10.26-2.55,19.79,19.79,0,0,1,8.71,2A16.72,16.72,0,0,1,564.76,263.19Z"
          />
          <path
            class="cls-1"
            d="M591.66,298.58c2.62,2.61,6.4,3.94,11.25,3.94a19.92,19.92,0,0,0,6.08-.94,13.39,13.39,0,0,0,4.81-2.65l-2.38-4.84A10.6,10.6,0,0,1,608,296a13,13,0,0,1-4.26.7,9,9,0,0,1-6.8-2.47c-1.58-1.64-2.38-4.06-2.38-7.2V255.68h15.91V250H594.53V238.53h-6.81V250h-9.35v5.72h9.35v31.75C587.72,292.21,589.05,296,591.66,298.58Z"
          />
          <path
            class="cls-1"
            d="M642.79,302.52c6.7,0,12-1.35,15.79-4a12.57,12.57,0,0,0,5.67-10.78,11.15,11.15,0,0,0-2.66-7.87,14.68,14.68,0,0,0-6.41-4.05,91.79,91.79,0,0,0-10-2.38,79.79,79.79,0,0,1-8-1.74,12.16,12.16,0,0,1-4.88-2.67,6.34,6.34,0,0,1-2-4.86,7.47,7.47,0,0,1,3.49-6.37c2.3-1.6,5.75-2.41,10.26-2.41a26.68,26.68,0,0,1,15.37,4.42l3-5.45a28.47,28.47,0,0,0-8.23-3.45,39.4,39.4,0,0,0-10.11-1.34c-6.44,0-11.53,1.4-15.14,4.16a12.87,12.87,0,0,0-5.42,10.64c0,3.46.91,6.21,2.7,8.17a15.06,15.06,0,0,0,6.56,4.19A88.68,88.68,0,0,0,643,279.15a69.64,69.64,0,0,1,7.67,1.64,12.76,12.76,0,0,1,4.82,2.52,5.83,5.83,0,0,1,1.93,4.62,7.27,7.27,0,0,1-3.48,6.42c-2.3,1.49-5.89,2.25-10.66,2.25A33.11,33.11,0,0,1,632.91,295a27.39,27.39,0,0,1-8.18-4.16l-3.06,5.35a26.32,26.32,0,0,0,9,4.54A40.37,40.37,0,0,0,642.79,302.52Z"
          />
          <path
            class="cls-2"
            d="M102.77,54.82A76,76,0,0,0,75.3,49.75C33.78,49.75,0,82.86,0,123.57s33.78,73.82,75.3,73.82,75.3-33.12,75.3-73.82V1.23A1.23,1.23,0,0,0,149.37,0h-1.23a26.93,26.93,0,0,0-26.89,26.9v96.67c0,24.52-20.61,44.47-45.95,44.47s-45.95-19.95-45.95-44.47S50,79.1,75.3,79.1c1.13,0,2.27,0,3.37.12A21.08,21.08,0,0,0,99.51,66l3.95-9.56a1.28,1.28,0,0,0,0-1A1.22,1.22,0,0,0,102.77,54.82Z"
          />
          <path
            class="cls-1"
            d="M662.17,197.61h1.22a1.24,1.24,0,0,0,1.24-1.23V123.57c0-40.71-33.78-73.82-75.3-73.82S514,82.86,514,123.57s33.78,73.82,75.3,73.82A75.3,75.3,0,0,0,636.5,181l.62-.49.31.73A26.86,26.86,0,0,0,662.17,197.61ZM589.33,168c-25.34,0-46-19.95-46-44.47S564,79.1,589.33,79.1s45.94,20,45.94,44.47S614.66,168,589.33,168Z"
          />
          <path
            class="cls-1"
            d="M340.85,141.2c0,31,25.67,56.19,57.23,56.19h7.18a1.24,1.24,0,0,0,1.23-1.24V169.27a1.23,1.23,0,0,0-1.23-1.23h-7.18c-15.37,0-27.87-12-27.87-26.84v-47h32.33A2.46,2.46,0,0,0,405,91.72V67.28a2.46,2.46,0,0,0-2.46-2.45H370.21V22.76A1.25,1.25,0,0,0,369,21.52h-1.22a26.93,26.93,0,0,0-26.9,26.9Z"
          />
          <path
            class="cls-1"
            d="M461.37,21.52h-1.22a26.93,26.93,0,0,0-26.9,26.9V141.2c0,31,25.67,56.19,57.22,56.19h7.18a1.25,1.25,0,0,0,1.24-1.24V169.27a1.24,1.24,0,0,0-1.24-1.23h-7.18c-15.37,0-27.87-12-27.87-26.84v-47h32.33a2.46,2.46,0,0,0,2.46-2.46V67.28a2.46,2.46,0,0,0-2.46-2.45H462.6V22.76A1.24,1.24,0,0,0,461.37,21.52Z"
          />
          <path
            class="cls-2"
            d="M320.62,130.26v-6.69c0-40.71-33.78-73.82-75.3-73.82S170,82.86,170,123.57s33.78,73.82,75.3,73.82a75.78,75.78,0,0,0,57.55-26.21,1.27,1.27,0,0,0,.26-1.08,1.25,1.25,0,0,0-.72-.9l-26.29-11.85a1.2,1.2,0,0,0-1.24.18A46.74,46.74,0,0,1,245.32,168c-25.34,0-45.95-19.95-45.95-44.47S220,79.1,245.32,79.1c19.08,0,36.39,11.65,43.07,29l.32.81h-48a24.41,24.41,0,0,0-24.37,24.38V137a1.25,1.25,0,0,0,1.24,1.24h95A8,8,0,0,0,320.62,130.26Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
